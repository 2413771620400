<template>
  <Dialog v-model:visible="entryDialog" :closable="false" :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '450px' }" :modal="true" class="p-fluid">
    <template #header>
      <p class="nice-font"><i class="pi pi-calendar"></i><span class="pl-1 mt-1">Zeitbereich auswählen</span></p>
    </template>
    <div @keydown.esc="hideMultiChange" @keydown.enter="saveMultiChange">
      <Panel>
        <div class="flex justify-content-center align-items-center p-0 flex-wrap">
            <div class="flex justify-content-center align-items-center p-0 mr-2">
                <span class="p-float-label">
                    <Calendar 
                    id="calendar-start"
                    v-model="localDateTime.start"
                    showTime hourFormat="24"
                    dateFormat="dd.mm.yy"
                    />
                    <label for="calender-start">Bereich von</label>
                </span>
            </div>
        </div>
        <div class="flex justify-content-center align-items-center p-2 flex-wrap">
            <i class="mif-arrow-down icon-inline text-2xl mr-2 fg-amber "></i>
        </div>
        <div class="flex justify-content-center align-items-center p-0 flex-wrap">
            <div class="flex justify-content-center align-items-center p-0">
                <span class="p-float-label">
                    <Calendar 
                    id="calendar-end"
                    v-model="localDateTime.end"
                    showTime hourFormat="24"
                    dateFormat="dd.mm.yy"
                    />
                    <label for="calender-end">Bereich bis</label>
                </span>
            </div>
        </div>
      </Panel>
    </div>
    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog" />
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog" :disabled="localDateTime.end <= localDateTime.start" />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'dialogDateSelect',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        dateTime: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.dialogState = this.showDialog;
        this.localDateTime.start = this.dateTime.start;
        this.localDateTime.end = this.dateTime.end;
        this.localDateTime.format = this.dateTime.format;
    },
    beforeUnmount() {
        this.resetDialogData();
    },
    computed: {
        entryDialog: function () {
            return this.dialogState;
        },
    },
    data() {
        return {
            dialogState: false,
            localDateTime: {
                start: null,
                end: null,
                format: null
            },
        };
    },
    watch: {
        showDialog() {
            this.multiParamChange();
            this.dialogState = this.showDialog;
        }
    },
    methods: {
        focusElement(event) {
            if (event !== undefined) {
                event.target.select();
            }
        },
        hideDialog() {
            this.dialogState = false;
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.dialogState = false;
            this.localDateTime.start = null;
            this.localDateTime.end = null;
            this.localDateTime.format = null;
        },
        saveDialog() {
            if (this.localDateTime.start && this.localDateTime.end && this.localDateTime.format) {
                this.dialogState = false;
                this.$emit('entrySave', this.localDateTime);
            }
        },
    },
});
</script>
